<template>
  <div class="login-container">
    <div class="login-content">
      <h2>Login</h2>

      <form class="login-content-form">
        <r-input v-model="form.email" label="Email" type="text" />
        <r-input v-model="form.password" label="Senha" type="password" />
        <div style="display:flex; justify-content: flex-end; width:100%">
            <router-link class="link" to="/auth/request">Esqueceu sua senha?</router-link>
        </div>
        <Actions @login="handleLogin()" :loading="loading" />
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import swal from 'sweetalert'
import { mapActions } from 'vuex'

export default Vue.extend({
  data: () => ({
    form: { email: '', password: '' },
    loading: false
  }),
  components: {
    RInput: () => import('@/components/input.vue'),
    Actions: () => import('./components/actions.vue')
  },
  methods: {
    ...mapActions('user', ['login']),
    async handleLogin () {
      if (!this.form.email || !this.form.password) {
        swal({
          title: 'Ooops!',
          text: 'Preencha email e senha para continuar.',
          icon: 'error',
          button: 'Ok'
        })
        return
      }

      this.loading = true
      const success = await this.login(this.form)
      console.log(success)
      if (success !== true) {
        swal({
          title: 'Ooops!',
          text: 'Email ou senha inválidos.',
          icon: 'error',
          button: 'Ok'
        })
      } else {
        this.$router.push('/')
      }
      this.loading = false
    }
  }
})
</script>

<style scoped>
.login-container {
  background: #ffffff !important;
  width: 600px;
  min-height: 400px;
  max-width: 98%;
  border-radius: 0.75rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.15);
  padding: 1.5rem;
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
}

.login-content,
.login-image {
  display: block;
}

.login-content {
  width: 500px;
  max-width: 100%;
}

.login-content h2,
.login-content h3 {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3rem;
  width: 100%;
}
.login-content h3 {
  text-align: left;
  margin-bottom: 2rem;
  color: #14a949;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.login-content-form {
  width: 100%;
  max-width: 96%;
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0.8rem;
}
</style>
